import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Alejandro Aguilar Jr | Full Stack Web Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Howdy! My name is',
  name: 'Alex Aguilar Jr.',
  subtitle: `I'm a full stack web developer and teacher.`,
  cta: 'About Me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `Currently, I'm a full stack engineer focused on the instruction of future developers 
  at Flatiron School. I have a bachelor's degree in Electronics Engineering Technology from Texas A&M
  and have worked within the industrial automation, semiconductor, and software engineering industries.`,
  paragraphTwo: `Throughout my career, I have found that my curiosity to tinker and understand specifics 
  of how things work has gotten me very far. Solutions don’t always present themselves easily to me, but 
  I have yet to find a problem I can’t debug with a little time and the right tools. I enjoy working 
  in a collaborative environment that will allow me to continually learn new tools and technologies.`,
  paragraphThree: `In my spare time, I enjoy playing sports and board games, making small JS shopping 
  scripts for my wife, and hanging out with my pets.`,
  resume: 'https://drive.google.com/file/d/1UugTQcsETF3XoAYkZDS_KkCVzlWbjPmO/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'whatcha.png',
    title: 'Whatcha Gonna Eat',
    info: `The app will help indecisive foodies pick a place to eat. 
    Based on your location & cuisine choices, it will find restaurants near you that match what you're looking for.`,
    info2: 'React, Hooks, Semantic UI',
    url: 'https://whatcha-gonna-eat.netlify.app/',
    repo: 'https://gitlab.com/bosskey59/whatcha-gonna-eat', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'acclimated.png',
    title: 'Acclimated',
    info: 'Weather app that suggests what a user should wear based on climate and chance of rain or snow at the user’s zip code.',
    info2: 'React, Redux, Rails, Bootstap',
    url: 'https://acclimated-frontend.herokuapp.com/',
    repo: 'https://github.com/bosskey59/Acclimated_frontend', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'eog.png',
    title: 'EOG Code Challenge',
    info: `This is a code challenge provided to applicants interested in EOG. It tracks live
     measurements by plotting them on a graph and displaying them as they change.`,
    info2: 'React, Redux, Hooks, GraphQl, Web Sockets',
    url: 'https://alejandroaguilar-eog-react-assessment.netlify.app/',
    repo: 'https://gitlab.com/bosskey59/eog-react-assesment', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: "Let's Chat",
  email: 'alejandro.aguilar.jr@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/alejandroaguilarjr',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/bosskey59',
    },
    {
      id: nanoid(),
      name: 'gitlab',
      url: 'https://gitlab.com/bosskey59',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
